import {
  Heading,
  Text,
  List,
  ListItem,
  VStack,
  useBreakpointValue,
  Link,
} from "@chakra-ui/react";
import Background1 from "../../images/Service1Background.compressed.svg";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";

const TermsAndConditions = () => {
  const c1 = useBreakpointValue({
    base: "", // For base (mobile) screens
    md: "web-1", // For medium screens
  });
  const navigate = useNavigate();
  const headerProps = {
    fontFamily: "Fondamento, sans-serif",
    mb: 2,
    color: "var(--astrology-nav)",
    as: "h2",
    size: "lg",
    mt: 4,
  };

  const textProps = {
    fontFamily: "Jost",
    fontSize: "18px",
    fontWeight: 400,
    textAlign: "justify",
    color: "var(--astrology-nav)",
  };

  return (
    <VStack>
      <VStack
        bgImage={`url('${Background1}')`}
        className={c1}
        w={"full"}
        bgRepeat="no-repeat"
        backgroundSize={"cover"}
        alignItems={"center"}
        justifyContent={"end"}
        py={"120px"}
      >
        <VStack
          width={{ base: "90vw", md: "80vw", xl: "60vw" }}
          alignItems={"center"}
        >
          <Heading {...headerProps} as="h1" size="xl" mb={4}>
            Terms and Conditions
          </Heading>
          <Text {...textProps} lineHeight={2}>
            Welcome to A Chosen Son ("Company," "we," "us," or "our"). These
            Terms and Conditions govern your access to and use of our services,
            website, and any associated platforms (collectively, the
            "Services"). By using our Services, you agree to abide by these
            Terms and Conditions. If you do not agree, please refrain from using
            our Services.
          </Text>
        </VStack>
      </VStack>
      <VStack maxW="800px" mx="auto" p={4} gap={4} alignItems={"start"}>
        <Heading {...headerProps}>1. Product Authenticity</Heading>
        <Text {...textProps}>
          We guarantee that all gemstones sold by us are 100% authentic and
          natural. Due to the nature of gemstones, slight variations in color,
          size, texture, and inclusions may occur. These natural variations do
          not affect the quality or authenticity of the product.
        </Text>
        <Heading {...headerProps}>2. No Refund Policy</Heading>
        <Text {...textProps}>
          All sales are final. We do not offer refunds for any reason, including
          but not limited to:
        </Text>
        <List styleType="disc" pl={5} {...textProps}>
          <ListItem>Change of mind after purchase</ListItem>
          <ListItem>
            Personal preference regarding the appearance or characteristics of
            the product
          </ListItem>
          <ListItem>Incorrect ordering by the customer</ListItem>
        </List>
        <Text {...textProps}>
          We encourage customers to review all product details and descriptions
          carefully before making a purchase.
        </Text>
        <Heading {...headerProps}>3. Exchange Policy</Heading>
        <Text {...textProps}>
          Exchanges are permitted only in the case of damaged or defective
          products, under the following conditions:
        </Text>
        <List styleType="disc" pl={5} {...textProps}>
          <ListItem>
            The product must be unused, in its original packaging, and in the
            same condition as received.
          </ListItem>
          <ListItem>
            The damage or defect must be reported within 24 hours of delivery.
          </ListItem>
          <ListItem>
            Sufficient proof of damage or defect (such as clear images or
            videos) must be provided.
          </ListItem>
          <ListItem>
            The replacement product will be dispatched only after verification
            and approval of the claim.
          </ListItem>
        </List>
        <Heading {...headerProps}>4. Shipping and Delivery</Heading>
        <Text {...textProps}>
          We ensure secure packaging and partner with reliable courier services
          for timely delivery. However, we are not responsible for shipping
          delays caused by:
        </Text>
        <List styleType="disc" pl={5} {...textProps}>
          <ListItem>Customs clearance procedures</ListItem>
          <ListItem>Logistics provider delays</ListItem>
          <ListItem>
            Force majeure events such as natural disasters, strikes, or
            unforeseen circumstances
          </ListItem>
        </List>
        <Text {...textProps}>
          Customers are advised to track their orders using the provided
          tracking details.
        </Text>
        <Heading {...headerProps}>5. Limitation of Liability</Heading>
        <Text {...textProps}>
          We are not liable for any issues arising from:
        </Text>
        <List styleType="disc" pl={5} {...textProps}>
          <ListItem>Personal dissatisfaction with the product</ListItem>
          <ListItem>
            Misuse, mishandling, or improper storage of the product
          </ListItem>
          <ListItem>
            Any allergic reactions or other health concerns resulting from
            product use
          </ListItem>
        </List>
        <Text {...textProps}>
          Customers assume full responsibility for their purchase decisions and
          product usage.
        </Text>
        <Heading {...headerProps}>6. Dispute Resolution</Heading>
        <Text {...textProps}>
          In case of any disputes, we encourage amicable resolution through
          direct communication. If disputes persist, they will be governed by
          and interpreted in accordance with the laws of India. All legal
          proceedings will be subject to the jurisdiction of courts in India.
        </Text>
        <Heading {...headerProps}>
          7. Data Collection and Privacy Policy
        </Heading>
        <Text {...textProps}>
          We collect and store personal information such as name, email,
          address, and usage details strictly for order processing and service
          improvement. We do not sell, trade, or disclose customer information
          to third parties except as required by law.
        </Text>
        <Text {...textProps}>
          For more information, please refer to our{" "}
          <span
            style={{
              cursor: "pointer",
              fontWeight: "600",
              textDecoration: "underline",
            }}
            onClick={() => {
              navigate("/privacy-policy");
            }}
          >
            Privacy Policy
          </span>
          .
        </Text>
        <Heading {...headerProps}>8. Contact Us</Heading>
        <Text {...textProps}>
          For support, inquiries, or concerns, please reach out to us via:
        </Text>
        <Text {...textProps}>
          <strong>Email:</strong>{" "}
          <Link href="mailto:support@achosenson.com">
            support@achosenson.com
          </Link>
        </Text>
        <Text {...textProps}>
          <strong>Phone:</strong>{" "}
          <Link href="tel:+919601465169">+91 9601465169</Link>
        </Text>
        <Text {...textProps}>
          <strong>Business Hours:</strong> Monday to Friday, 9 AM to 6 PM IST
        </Text>
        <Text {...textProps} mt={4} fontWeight={500}>
          By continuing to use our Services, you acknowledge that you have read,
          understood, and agreed to these Terms and Conditions.
        </Text>
      </VStack>
      <Footer />
    </VStack>
  );
};

export default TermsAndConditions;
