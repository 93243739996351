import { Loader } from "@astrology/shared-components";
import { Stack, VStack } from "@chakra-ui/react";
import React, { Suspense } from "react";
import { Navigate } from "react-router-dom";
import TermsAndConditions from "../features/TearmsAndCondition/TermsAndConditions.js";
import PrivacyPolicy from "../features/PrivacyPolicy/PrivacyPolicy.js";

// Lazy load components
// const Collaboration = React.lazy(() =>
//   import("../features/Collaboration/Collaboration")
// );
const ContactUs = React.lazy(() => import("../features/ContactUs/ContactUs"));
const FAQS = React.lazy(() => import("../features/FAQS/FAQS"));
const HomeComponent = React.lazy(() => import("../features/Home/Home"));
const TeamConsultation = React.lazy(() =>
  import("../features/Services/TeamConsultation/Consultation")
);

// const Consultant = React.lazy(() =>
//   import("../features/Services/Consultation_V2/Consultation")
// );
const WrittenNotes = React.lazy(() =>
  import("../features/Services/Notes/WrittenNotes")
);
const Consultant = React.lazy(() =>
  import("../features/Services/OneToOneConsultant_V1/OneToOneConsultant")
);
const Commingsoon = React.lazy(() =>
  import("../features/CommingSoon/Commingsoon")
);
const Donation = React.lazy(() => import("../features/Donation/Donation"));
const LagnaAndNavamasChart = React.lazy(() =>
  import("../features/FreeServices/LagnaAndNavamasChart")
);
const Gemstones = React.lazy(() => import("../features/Gemstones/Gemstones"));
const Numerology = React.lazy(() =>
  import("../features/Numerology/Numerology")
);
// const Services = React.lazy(() => import("../features/Services/Services"));
const ServiceTermsAndConditions = React.lazy(() =>
  import("../features/ServiceTermsConditions/Terms.js")
);
// const GemstoneCalculator = React.lazy(() =>
//   import("../features/FreeServices/GemstoneCalculator/GemstoneCalculator.js")
// );
const CareerComponent = React.lazy(() =>
  import("../features/Career/Career.js")
);

const ArtGallery = React.lazy(() =>
  import("../features/ArtGallery/ArtGallery.js")
);

const Blog = React.lazy(() => import("../features/Blog/Blog.js"));
const BlogDetails = React.lazy(() =>
  import("../features/Blog/components/BlogDetails.js")
);

const serviceSubComponents = [
  {
    path: "/services/consultation",
    name: "Voice note by jay",
    menuItem: false,
    component: <Consultant />,
  },
  {
    path: "/services/team-consultation",
    name: (
      <>
        Solution Within 100{" "}
        <span style={{ textTransform: "capitalize" }}>Hrs</span>
      </>
    ),
    menuItem: false,
    component: <TeamConsultation />,
  },
  {
    path: "/services/notes",
    name: "Written Notes",
    menuItem: false,
    component: <WrittenNotes />,
  },
  {
    path: "/services/numerology",
    name: "Numerology",
    menuItem: false,
    component: <Numerology />,
  },
  {
    path: "/services/reports",
    name: "Reports",
    menuItem: false,
    component: <Commingsoon />,
  },
];

const productsSubComponents = [
  {
    path: "/products/gemstones",
    name: "Gemstones",
    menuItem: false,
    component: <Gemstones />,
  },
];

const freeCalculatorsSubComponents = [
  {
    path: "/free-calculators/lagna-and-navamsa-chart",
    name: "Lagna And Navamas Chart",
    menuItem: false,
    component: <LagnaAndNavamasChart />,
  },
  // {
  //   path: "/free-calculators/gemstone-calculator",
  //   name: "Gemstone Calculator",
  //   menuItem: false,
  //   component: (
  //     <Suspense
  //       fallback={
  //         <VStack>
  //           <Loader />
  //         </VStack>
  //       }
  //     >
  //       <GemstoneCalculator />
  //     </Suspense>
  //   ),
  // },
];

//  if routes are changed, also change it to the footer file
export const authRoutes = [
  {
    path: "*",
    name: "",
    menuItem: false,
    component: <Navigate to="/home" />,
  },
  {
    path: "/",
    name: "",
    menuItem: false,
    component: <Navigate to="/home" />,
  },
  {
    path: "/home",
    name: "Home",
    menuItem: true,
    component: (
      <Suspense
        fallback={
          <VStack>
            <Loader />
          </VStack>
        }
      >
        <HomeComponent />
      </Suspense>
    ),
  },
  {
    path: "/services/consultation",
    name: "Voice note by Jay",
    menuItem: false,
    component: (
      <Suspense
        fallback={
          <VStack>
            <Loader />
          </VStack>
        }
      >
        <Consultant />
      </Suspense>
    ),
  },
  {
    path: "/services/team-consultation",
    name: (
      <>
        Solution Within 100{" "}
        <span style={{ textTransform: "capitalize" }}>Hrs</span>
      </>
    ),
    menuItem: false,
    component: (
      <Suspense
        fallback={
          <VStack>
            <Loader />
          </VStack>
        }
      >
        <TeamConsultation />
      </Suspense>
    ),
  },
  {
    path: "/services/notes",
    name: "Written Notes",
    menuItem: false,

    component: (
      <Suspense
        fallback={
          <VStack>
            <Loader />
          </VStack>
        }
      >
        <WrittenNotes />
      </Suspense>
    ),
  },
  {
    path: "/services/numerology",
    name: "Numerology",
    menuItem: false,
    component: (
      <Suspense
        fallback={
          <VStack>
            <Loader />
          </VStack>
        }
      >
        <Numerology />
      </Suspense>
    ),
  },
  {
    path: "/services/reports",
    name: "Reports",
    menuItem: false,
    component: (
      <Suspense
        fallback={
          <VStack>
            <Loader />
          </VStack>
        }
      >
        <Commingsoon />
      </Suspense>
    ),
  },
  {
    path: "/services/consultation",
    name: "Services",
    menuItem: true,
    subComponent: serviceSubComponents,
    component: (
      <Suspense
        fallback={
          <VStack>
            <Loader />
          </VStack>
        }
      >
        <Consultant />
      </Suspense>
    ),
  },
  {
    path: "/products/gemstones",
    name: "Products",
    menuItem: true,
    subComponent: productsSubComponents,
    component: (
      <Suspense
        fallback={
          <VStack>
            <Loader />
          </VStack>
        }
      >
        <Gemstones />
      </Suspense>
    ),
  },
  {
    path: "/free-calculators/lagna-and-navamsa-chart",
    name: "Free Calculators",
    menuItem: true,
    subComponent: freeCalculatorsSubComponents,
    component: (
      <Suspense
        fallback={
          <VStack>
            <Loader />
          </VStack>
        }
      >
        <LagnaAndNavamasChart />
      </Suspense>
    ),
  },
  // {
  //   path: "/free-calculators/gemstone-calculator",
  //   name: "Gemstone Calculator",
  //   menuItem: false,
  //   component: (
  //     <Suspense
  //       fallback={
  //         <VStack>
  //           <Loader />
  //         </VStack>
  //       }
  //     >
  //       <GemstoneCalculator />
  //     </Suspense>
  //   ),
  // },
  {
    path: "/art-gallery",
    name: "Art Gallery",
    menuItem: true,
    component: (
      <Suspense
        fallback={
          <VStack>
            <Loader />
          </VStack>
        }
      >
        <ArtGallery />
      </Suspense>
    ),
  },
  {
    path: "/donation",
    name: "Donation",
    menuItem: false,
    component: (
      <Suspense
        fallback={
          <VStack>
            <Loader />
          </VStack>
        }
      >
        <Donation />
      </Suspense>
    ),
  },
  {
    path: "/faqs",
    name: "FAQS",
    menuItem: false,
    component: (
      <Suspense
        fallback={
          <VStack>
            <Loader />
          </VStack>
        }
      >
        <FAQS />
      </Suspense>
    ),
  },
  // {
  //   path: "/collaboration",
  //   name: "Collaboration",
  //   menuItem: true,
  //   component: (
  //     <Suspense
  //       fallback={
  //         <VStack>
  //           <Loader />
  //         </VStack>
  //       }
  //     >
  //       <Collaboration />
  //     </Suspense>
  //   ),
  // },
  {
    path: "/contact",
    name: "Contact Team",
    menuItem: true,
    component: (
      <Suspense
        fallback={
          <VStack>
            <Loader />
          </VStack>
        }
      >
        <ContactUs />
      </Suspense>
    ),
  },
  {
    path: "/career",
    name: "Career",
    menuItem: true,
    component: (
      <Suspense
        fallback={
          <VStack>
            <Loader />
          </VStack>
        }
      >
        <CareerComponent />
      </Suspense>
    ),
  },
  {
    path: "/blogs",
    name: "Blogs",
    menuItem: true,
    component: (
      <Suspense
        fallback={
          <VStack>
            <Loader />
          </VStack>
        }
      >
        <Blog />
      </Suspense>
    ),
  },
  {
    path: "/blogs/:id",
    name: "Blog Details",
    menuItem: false,
    component: (
      <Suspense
        fallback={
          <VStack>
            <Loader />
          </VStack>
        }
      >
        <BlogDetails />
      </Suspense>
    ),
  },
  {
    path: "/tandc",
    name: "Terms and Conditions",
    menuItem: false,
    component: (
      <Suspense
        fallback={
          <VStack>
            <Loader />
          </VStack>
        }
      >
        <ServiceTermsAndConditions />
      </Suspense>
    ),
  },
  {
    path: "/terms-and-conditions",
    name: "Terms and Conditions",
    menuItem: false,
    component: (
      <Suspense
        fallback={
          <VStack>
            <Loader />
          </VStack>
        }
      >
        <TermsAndConditions />
      </Suspense>
    ),
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    menuItem: false,
    component: (
      <Suspense
        fallback={
          <VStack>
            <Loader />
          </VStack>
        }
      >
        <PrivacyPolicy />
      </Suspense>
    ),
  },
];
