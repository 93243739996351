import {
  Box,
  Heading,
  Text,
  List,
  ListItem,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import Background1 from "../../images/Service1Background.compressed.svg";
import Footer from "../Footer/Footer";

const PrivacyPolicy = () => {
  const c1 = useBreakpointValue({
    base: "", // For base (mobile) screens
    md: "web-1", // For medium screens
  });
  const headerProps = {
    fontFamily: "Fondamento, sans-serif",
    mb: 2,
    color: "var(--astrology-nav)",
    as: "h2",
    size: "lg",
    mt: 4,
  };

  const textProps = {
    fontFamily: "Jost",
    fontSize: "18px",
    fontWeight: 400,
    textAlign: "justify",
    color: "var(--astrology-nav)",
  };

  return (
    <VStack>
      <VStack
        bgImage={`url('${Background1}')`}
        className={c1}
        w={"full"}
        bgRepeat="no-repeat"
        backgroundSize={"cover"}
        alignItems={"center"}
        justifyContent={"end"}
        py={"120px"}
      >
        <VStack
          width={{ base: "90vw", md: "80vw", xl: "60vw" }}
          alignItems={"center"}
        >
          <Heading {...headerProps} as="h1" size="xl" mb={4}>
            Privacy Policy
          </Heading>
          <Text {...textProps} lineHeight={2}>
            A Chosen Son ("Company," "we," "us," or "our") values your privacy.
            This Privacy Policy outlines how we collect, use, disclose, and
            safeguard your information when you use our website (the "Website").
            By accessing or using our Website, you consent to the practices
            described in this Privacy Policy.
          </Text>
        </VStack>
      </VStack>
      <VStack maxW="800px" mx="auto" p={4} gap={4} alignItems={"start"}>
        <Heading {...headerProps}>Information We Collect</Heading>
        <Text {...textProps}>
          When you use our Website, we may automatically collect:
        </Text>
        <List styleType="disc" pl={5} {...textProps}>
          <ListItem>
            <strong>Usage Data:</strong> Information about your interaction with
            the Website, such as pages viewed, time spent, and features used.
          </ListItem>
          <ListItem>
            <strong>Device Information:</strong> Details about the device used
            to access the Website, including IP address, browser type, operating
            system, and device identifiers.
          </ListItem>
          <ListItem>
            <strong>Cookies and Tracking Technologies:</strong> Data collected
            through cookies, beacons, or similar technologies to enhance user
            experience and analyze trends.
          </ListItem>
        </List>

        <Heading {...headerProps}>How We Use Your Information</Heading>
        <List styleType="disc" pl={5} {...textProps}>
          <ListItem>Analyze Website usage and improve functionality.</ListItem>
          <ListItem>Personalize and enhance user experience.</ListItem>
          <ListItem>
            Ensure Website security and prevent unauthorized activities.
          </ListItem>
          <ListItem>Comply with legal obligations.</ListItem>
        </List>

        <Heading {...headerProps}>Cookies and Analytics</Heading>
        <Text {...textProps}>
          We use Google Analytics to collect and analyze Website usage data.
          This information helps us understand how users interact with our
          Website and improve its performance. Google Analytics collects
          information such as your IP address, browser type, and pages visited.
          For more details, refer to{" "}
          <span
            style={{
              cursor: "pointer",
              fontWeight: "600",
              textDecoration: "underline",
            }}
            onClick={() =>
              window.open("https://policies.google.com/privacy", "_blank")
            }
          >
            Google’s Privacy Policy
          </span>
          .
        </Text>
        <Text {...textProps}>
          You can opt out of Google Analytics data collection by using the{" "}
          <span
            style={{
              cursor: "pointer",
              fontWeight: "600",
              textDecoration: "underline",
            }}
            onClick={() =>
              window.open("https://tools.google.com/dlpage/gaoptout", "_blank")
            }
          >
            Google Analytics Opt-Out Browser Add-on
          </span>
          .
        </Text>

        <Heading {...headerProps}>Data Security</Heading>
        <Text {...textProps}>
          We implement reasonable administrative, technical, and physical
          safeguards to protect your information. However, no data transmission
          or storage system can be guaranteed to be 100% secure.
        </Text>

        <Heading {...headerProps}>Changes to This Privacy Policy</Heading>
        <Text {...textProps}>
          We may update this Privacy Policy from time to time to reflect changes
          in our practices or applicable laws. We will notify you of significant
          updates by posting the revised policy on our Website.
        </Text>
      </VStack>
      <Footer />
    </VStack>
  );
};

export default PrivacyPolicy;
