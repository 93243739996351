import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Textarea,
} from "@chakra-ui/react";
import React from "react";

const TextArea = ({
  validationErrors,
  innerref,
  hideLabel,
  helperText,
  labelColor,
  fontSize,
  helperTextPosition,
  ...props
}) => {
  let keys = innerref && innerref.name && innerref.name.split(".");
  const validation =
    validationErrors &&
    (keys.length > 1
      ? validationErrors?.[keys[0]]?.[keys[1]]
      : validationErrors?.[keys[0]]);

  let textAreaId = props?.id
    ? props.id
    : props?.name && typeof props?.name === "string"
    ? props?.name
        ?.split(" ")
        ?.join("-")
        ?.toLowerCase()
        .replace(/[^a-zA-Z- ]/g, "")
    : props?.placeholder && typeof props?.placeholder === "string"
    ? props?.placeholder
        ?.split(" ")
        ?.join("-")
        ?.toLowerCase()
        .replace(/[^a-zA-Z- ]/g, "")
    : "";
  return (
    <React.Fragment>
      <FormControl
        name={props.name}
        isInvalid={validation}
        isRequired={props.required}
      >
        {!hideLabel ? (
          <HStack>
            <FormLabel
              fontSize={fontSize ?? "18px"}
              color={`${labelColor}` ?? " #030505"}
              fontWeight={400}
              w={"100%"}
              textTransform={"capitalize"}
              as="legend"
              mr="2px"
              mb={"6px"}
            >
              {props.name || props.placeholder}
            </FormLabel>
          </HStack>
        ) : null}
        <Textarea
          {...props}
          {...innerref}
          width="100%"
          id={textAreaId}
          placeholder={props.placeholder ? props.placeholder : ""}
          borderColor={"var(--atlantic-100)"}
          errorBorderColor={"#F8B4B4"}
          boxShadow={"none !important"}
          fontSize={fontSize ?? "14px"}
          height={props.textBoxheight ? props.textBoxheight : "36px"}
          _placeholder={{ color: "var(--atlantic-200)" }}
          _disabled={{
            bg: "var(--atlantic-50)",
            color: "var(--atlantic-300)",
          }}
        />
        {validation ? (
          <Errors errors={validation} />
        ) : props.hideHelperText ? (
          ""
        ) : (
          <FormHelperText float={helperTextPosition || "none"}>
            {helperText || ""}&nbsp;
          </FormHelperText>
        )}
      </FormControl>
    </React.Fragment>
  );
};

const Errors = ({ errors }) => {
  if (errors.types && Object.entries(errors.types).length > 0) {
    return Object.entries(errors.types).map(([type, message]) => (
      <FormErrorMessage key={type}>{message}</FormErrorMessage>
    ));
  } else {
    return <FormErrorMessage>{errors.message}</FormErrorMessage>;
  }
};

export default TextArea;
