import { Button } from "@astrology/shared-components";
import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import bgNav from "../images/bgNav.compressed.svg";
import logo from "../images/logo.webp";
import Advertise from "../features/Advertise";
import { ReactComponent as VectorIcon } from "../images/vector.svg";

const activeStyle = {
  "&:hover": { textDecoration: "underline" },
};

export default function SidebarWithHeader({ routes, children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box minH="100vh" bg={useColorModeValue("white", "gray.900")}>
      <Navigation routes={routes} />
      <Box transition="0.5s ease">{children}</Box>
    </Box>
  );
}

const NavigationDisplay = ({ ele }) => {
  let location = useLocation();

  return (
    <Link
      as={RouterLink}
      to={ele.path}
      style={
        location.pathname.indexOf(ele?.path) !== -1
          ? {
            textDecoration: "underline",
          }
          : {
            textDecoration: "none",
          }
      }
      _focus={{ boxShadow: "none" }}
      align={"center"}
      fontSize={{ md: "10px", lg: "16px" }}
      lineHeight={"22px"}
      fontWeight={500}
      textTransform={"uppercase"}
      p={"8px"}
      role="group"
      cursor="pointer"
      width={"inherit"}
      color="var(--astrology-nav)"
      _hover={activeStyle}
    >
      <Box>{ele.name}</Box>
    </Link>
  );
};

const MobileNavigationDisplay = ({
  ele,
  textDisplay,
  align = "center",
  color,
  linkColor,
  onClose,
}) => {
  let location = useLocation();

  return (
    <Box
      align={align ? align : "center"}
      p={"8px"}
      role="group"
      cursor="pointer"
      width={"inherit"}
      color={color ? color : "var(--astrology-nav)"}
    >
      {textDisplay ? (
        <Text
          style={{
            textDecoration: "none",
            fontSize: "16px",
            lineHeight: "22px",
            fontWeight: 500,
            textTransform: "uppercase",
            color: color ? color : "inherit",
          }}
        >
          {ele.name}
        </Text>
      ) : (
        <Link
          as={RouterLink}
          to={ele.path}
          style={
            location.pathname.indexOf(ele?.path) !== -1
              ? {
                textDecoration: "underline",
                fontSize: "16px",
                lineHeight: "22px",
                fontWeight: 500,
                textTransform: "uppercase",
              }
              : {
                textDecoration: "none",
                fontSize: "16px",
                lineHeight: "22px",
                fontWeight: 500,
                textTransform: "uppercase",
                color: linkColor ? linkColor : "var(--astrology-mobnav-txt)",
              }
          }
          _focus={{ boxShadow: "none" }}
          width={"100%"}
          onClick={() => {
            onClose();
          }}
        >
          {ele.name}
        </Link>
      )}
    </Box>
  );
};
const Navigation = ({ routes }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  return (
    <>
      <Flex
        px={{ base: 4, md: 4 }}
        py={{ base: "3", md: "1" }}
        top={6}
        zIndex={"1080"}
        w={"full"}
        alignItems={"center"}
        justifyContent={"space-between"}
        direction={"column"}
      >
        <Flex justifyContent={{ base: "end" }} w={"full"}>
          {/* web view */}
          <HStack
            display={{ base: "none", lg: "flex" }}
            gap={6}
            mr={2}
            my={0}
            h={"110px"}
            alignItems={"start"}
            justifyContent={"start"}
          >
            {routes?.map((ele) => {
              return ele?.menuItem ? (
                ele?.subComponent ? (
                  <Menu offset={[4, -6]}>
                    <MenuButton maxH={"40px"}>
                      <NavigationDisplay ele={ele} />
                    </MenuButton>
                    <Portal>
                      <MenuList
                        zIndex={"popover"}
                        bgImage={bgNav}
                        bgPosition={"center"}
                        bgRepeat={"no-repeat"}
                        bgSize={"cover"}
                      >
                        {ele?.subComponent?.map((e) => {
                          return (
                            <MenuItem background={"transparent"} p={0}>
                              <NavigationDisplay ele={e} />{" "}
                            </MenuItem>
                          );
                        })}
                      </MenuList>
                    </Portal>
                  </Menu>
                ) : (
                  <NavigationDisplay ele={ele} />
                )
              ) : (
                <></>
              );
            })}
            <Button
              variant="trNoOutline"
              fontSize="16px"
              lineHeight="28px"
              fontWeight={500}
              title="Book a call"
              border={"1px solid"}
              onClick={(e) => {
                e.preventDefault();
                window.open("https://wa.me/+919601465169", "_blank");
              }}
            />
          </HStack>
          {/* web view end */}

          {/* mobile view start */}
          <IconButton
            transition="all 0.3s"
            _focus={{ boxShadow: "none" }}
            display={{ base: "flex", lg: "none" }}
            variant="outline"
            border="1px solid #68030D"
            borderRadius={"10px"}
            aria-label="open menu"
            icon={<VectorIcon />}
            onClick={onOpen}
            zIndex={"1111"}
          />
        </Flex>

        <Drawer placement={"top"} onClose={onClose} isOpen={isOpen}>
          <DrawerOverlay />
          <DrawerContent bgColor={"var(--astrology-mob-nav-bg)"}>
            <DrawerHeader padding={4} display="flex" justifyContent={"end"}>
              <Button
                variant="trPrimary"
                name={<CloseIcon boxSize={3} onClick={onClose} />}
              />
            </DrawerHeader>
            <DrawerBody>
              {routes?.map((ele) => {
                return ele?.menuItem ? (
                  ele?.subComponent ? (
                    <Accordion allowToggle>
                      <AccordionItem border="none">
                        {({ isExpanded }) => (
                          <>
                            <AccordionButton p={0} borderRadius={8}>
                              {isExpanded ? (
                                <Flex
                                  bg={"var(--astrology-nav)"}
                                  width={"full"}
                                  alignItems={"center"}
                                  borderRadius={8}
                                  justifyContent={"space-between"}
                                >
                                  <MobileNavigationDisplay
                                    ele={ele}
                                    textDisplay={true}
                                    align="start"
                                    color={"var(--white-color)"}
                                  />
                                  <AccordionIcon
                                    color={"var(--white-color)"}
                                    mr={2}
                                  />
                                </Flex>
                              ) : (
                                <MobileNavigationDisplay
                                  ele={ele}
                                  textDisplay={true}
                                  color={"var(--astrology-mobnav-txt)"}
                                />
                              )}
                            </AccordionButton>
                            <AccordionPanel pb={4} bg={"#FEF0DD"}>
                              {ele?.subComponent?.map((e) => {
                                return (
                                  <MobileNavigationDisplay
                                    ele={e}
                                    color={"var(--astrology-nav)"}
                                    linkColor={"var(--astrology-nav)"}
                                    onClose={onClose}
                                  />
                                );
                              })}
                            </AccordionPanel>{" "}
                          </>
                        )}
                      </AccordionItem>
                    </Accordion>
                  ) : (
                    <MobileNavigationDisplay ele={ele} onClose={onClose} />
                  )
                ) : (
                  <></>
                );
              })}
            </DrawerBody>
          </DrawerContent>
        </Drawer>
        {/* mobile view end */}

        <Flex
          top={{ base: 0, lg: "50px" }}
          minWidth="250px"
          maxWidth="250px"
          overflow="visible"
          zIndex={"1080"}
          backgroundColor="#fff"
          sx={{
            clipPath: `path("M250 0H0V110C0 123.132 3.23322 136.136 9.51506 148.268C15.7969 160.401 25.0043 171.425 36.6117 180.711C43.586 186.29 55.3913 186.939 66.1923 186.189C67.9962 186.064 69.8221 186 71.6667 186C94.2807 186 114.09 195.608 125.063 210L125.354 210C136.327 195.608 156.136 186 178.75 186C180.554 186 182.341 186.061 184.106 186.181C194.805 186.907 206.481 186.236 213.388 180.711C224.996 171.425 234.203 160.401 240.485 148.268C246.767 136.136 250 123.132 250 110V0Z")`,
            clipRule: "nonzero",
            transform: "translateY(50%)",
            transformOrigin: "center bottom",
          }}
          marginTop={{ base: "-150px", lg: "-170px", xl: "-180px" }}
        >
          <Image
            src={logo}
            alt="A Chosen Son"
            title="A Chosen Son"
            cursor="pointer"
            onClick={() => navigate("/home")}
            height="120%"
            width="120%"
            objectFit="contain"
            transform="translateY(-10%)"
          />
        </Flex>
      </Flex>
    </>
  );
};
